import { createSlice } from '@reduxjs/toolkit'



export const counterSlice = createSlice({
    name: 'web3',
    initialState: {
        provider: null,
        address: false,
        signer: null,
        web3Modal: null,
        connected: false,
        plugincontract: null,
        xdcbridgecontract: null,
        chainId: null,
        networkInfo: null
    },
    reducers: {
        clearWeb3: async (state, action) => {
            await state.web3Modal.clearCachedProvider();
            state.provider = null
            state.address = false
            state.signer = null
            state.web3Modal = null
            state.connected = false
            state.plugincontract = null
            state.xdcbridgecontract = null
            state.chainId = null
            state.networkInfo = null
            window.location.reload()
        },
        setWeb3: (state, action) => {
            state.provider = action.payload.provider
            state.address = action.payload.address
            state.signer = action.payload.signer
            state.web3Modal = action.payload.web3Modal
            state.plugincontract = action.payload.plugincontract
            state.xdcbridgecontract = action.payload.xdcbridgecontract
            state.connected = true
            state.chainId = action.payload.chainId
            state.networkInfo = action.payload.networkInfo

        },
        setChain: (state, action) => {
            state.chainId = action.payload.chainId

        }

    },
})

export const { clearWeb3, setWeb3, setChain } = counterSlice.actions



export default counterSlice.reducer
