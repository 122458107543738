import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser } from './reducers/authReducer';
import { logoutUser } from './actions/authAction';

import axios from 'axios'
import { API_URL } from './actions/constant'
import Loader from "./components/common/Spinner"



import { Provider } from 'react-redux';
import store from './store';
import './App.css';

axios.defaults.baseURL = API_URL;

const Landing = React.lazy(() => import('./components/layouts/Landing'));

if (localStorage.jwtToken) {
  //Set Auth Token header Auth
  setAuthToken(localStorage.jwtToken);
  //Decode Token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUser());
    //ToDO : clear current profile
    //Redirect to Login page
    window.location.href = '/login';
  }

}




export default function App() {
  return (
    <Provider store={store}>
      <Router
        exact
        path="/"
      >
        <Suspense fallback={<Loader />}>
          {/* <div class="nk-app-root"> */}
          {/* ADMIN ROUTE */}
          <Routes>
            <Route index exact path="/" element={<Landing />} />
          </Routes>
          {/* </div> */}
        </Suspense>
      </Router>
    </Provider>
  )
}
