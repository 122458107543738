
import axios from "axios";
export const IMAGE_URL = 'http://localhost:5004/static/';
// export const API_URL = {
//   v1: 'http://localhost:5004',
//   v2: 'http://localhost:5004',
// };

export const API_URL = {
  v1: 'https://pliswap.goplugin.co',
  v2: 'https://pliswap.goplugin.co',
};


// export const IMAGE_URL = 'http://207.180.239.124:5002/static/';
// export const API_URL = 'http://207.180.239.124:5002';


export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')

export const NETWORKS = {
  50: {
    PLI_CONTRACT_ADDRESS: "0xff7412ea7c8445c46a8254dfb557ac1e48094391",
    XDC_BRIDGE_ADDRESS: "0x7971A612C2bad8c4eD565339852091D14810828b",
    RPC_URL: "https://rpc.xinfin.network",
    EXPLORER_LINK: "https://explorer.xinfin.network/",
    NETWORK: 50,
    BTN_LABEL: "Mint on Polygon",
    TICKER: "XDC Network",
    NETWORK_TYPE: "Mainnet",
    TYPE: "Mint",
    SYMBOL: "XDC",
    DECIMAL: 18
  },
  51: {
    PLI_CONTRACT_ADDRESS: "0x33f4212b027e22af7e6ba21fc572843c0d701cd1",
    XDC_BRIDGE_ADDRESS: "0x5d4899fB857025A52e3Fb6cAFE8D5A363CEFbc4B",
    RPC_URL: "https://erpc.apothem.network",
    EXPLORER_LINK: "https://explorer.apothem.network/",
    NETWORK: 51,
    BTN_LABEL: "Mint on Polygon",
    TICKER: "XDC Network Apothem",
    NETWORK_TYPE: "Testnet",
    TYPE: "Mint",
    SYMBOL: "XDC",
    DECIMAL: 18
  },
  137: {
    PLI_CONTRACT_ADDRESS: "0x7540F227daEB674FC1a81D362401A90E2228234d",
    RPC_URL: "https://rpc-mainnet.maticvigil.com",
    EXPLORER_LINK: "https://polygonscan.com/",
    NETWORK: 137,
    BTN_LABEL: "Burn on Polygon",
    TICKER: "Polygon",
    NETWORK_TYPE: "Mainnet",
    TYPE: "Burn",
    SYMBOL: "XDC",
    DECIMAL: 18

  },
  80001: {
    PLI_CONTRACT_ADDRESS: "0x26FD686728D7bFEfD575592e1ef75E607EB1A209",
    RPC_URL: "https://polygon-mumbai-bor-rpc.publicnode.com",
    EXPLORER_LINK: "https://mumbai.polygonscan.com/",
    NETWORK: 80001,
    BTN_LABEL: "Burn on Polygon",
    NETWORK_TYPE: "Testnet",
    TICKER: "Polygon Mumbai",
    TYPE: "Burn",
    SYMBOL: "XDC",
    DECIMAL: 18

  },
  80002: {
    PLI_CONTRACT_ADDRESS: "0x7540F227daEB674FC1a81D362401A90E2228234d",
    RPC_URL: "https://80002.rpc.thirdweb.com",
    EXPLORER_LINK: "https://www.oklink.com/amoy/",
    NETWORK: 80002,
    BTN_LABEL: "Burn on Polygon",
    NETWORK_TYPE: "Testnet",
    TICKER: "Polygon Amoy",
    TYPE: "Burn",
    SYMBOL: "XDC",
    DECIMAL: 18

  }
}



export const AVAILABLE_NETWORK = [
  { networkName: "XDC Network" },
  { networkName: "Apothem" },
  { networkName: "Polygon" },
  // { networkName: "Polygon Mumbai", },
  { networkName: "Polygon Amoy", },


]

export const RPC_URL_LIST = {
  "ETH": "https://etherscan.io/tx",
  "WBTC": "https://etherscan.io/tx",
  "USDT": "https://etherscan.io/tx",
  "XDC": "https://explorer.xinfin.network/txs",
  "MATIC": "https://polygonscan.com/tx",
}

export const WALLET_URL_LIST = {
  "ETH": "https://etherscan.io/address",
  "WBTC": "https://etherscan.io/address",
  "USDT": "https://etherscan.io/address",
  "XDC": "https://explorer.xinfin.network/address",
  "MATIC": "https://polygonscan.com/address",

}





export const uploadFileServer = async (data) => {

  try {

    var response = await axios.post(`/upload`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const getTxnStatus = async (txHash, provider) => {
  let transactionReceipt = null
  while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
    transactionReceipt = await provider.getTransactionReceipt(txHash);
    await sleep(3000)
  }
  if (transactionReceipt.status) {
    return [txHash, true, transactionReceipt.blockNumber];
  } else {
    return [txHash, false, 0];
  }
}
export const addMilesSeparator = (n) => {
  n = Math.round(n * 100000) / 100000;
  const parts = n.toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  const end = numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  return end;
}